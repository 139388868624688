/* eslint-disable no-debugger */
import { useFormik, FormikErrors } from "formik";
import { Button } from "../../components/Button/Button";
import FileUploader from "../../components/FileUploader";
import Input from "../../components/Input";
import { ReactComponent as Cross } from "../../icons/cross.svg";
import Modal from "../../components/Modal";
import { useEffect, useMemo, useState } from "react";
import Table, { Column } from "../../components/Table";
import Loader from "../../components/Loader";
import { v4 as uuidv4 } from "uuid";
import "./NewBatch.scss";
import PageWrapper from "../../wrappers/PageWrapper";
import Card from "../../components/Card";
import { useModal } from "../../hooks/useModal";
import Papa from "papaparse";

interface FormValues {
  name: string;
  file: File | undefined;
}

export interface PostNewBatchInput {
  name: string;
  column: string;
  data: any[];
}

interface Props {
  onClickBack: () => void;
  onSendBatch: (name: string, items: any[], selected: string, withEmails: boolean) => void;
}

const generateCol = (k: string): Column<any> => {
  return {
    size: "206px",
    label: k,
    render: (v) => {
      return <span className="overflow-hidden text-ellipsis whitespace-nowrap text-[11px] leading-[13px] ">{v[k]}</span>;
    }
  };
};

export default function NewBatch({ onClickBack, onSendBatch }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [selected, setSelected] = useState<string | undefined>();
  const [error, setError] = useState<string>("");
  const [parseFileError, setParseFileError] = useState<string>("");
  const [parsedCsvFile, setParsedCsvFile] = useState<any[]>([]);
  const { isModalOpened, openModal, closeModal } = useModal();
  //TODO: uncomment when batch with email works in BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { isModalOpened: isConfirmModalOpened, openModal: openConfirmModal, closeModal: closeConfirmModal } = useModal();

  const columns = useMemo(() => {
    return items?.length
      ? Object.keys(items[0])
          .filter((c) => c !== "id")
          .map((c) => generateCol(c))
      : [];
  }, [items]);

  const { handleSubmit, handleChange, handleBlur, errors, touched, values, setFieldValue, setFieldTouched, setFieldError, resetForm } =
    useFormik<FormValues>({
      initialValues: {
        name: "",
        file: undefined
      },
      validate: (values: FormValues) => {
        const errors: FormikErrors<FormValues> = {};

        if (!values.name) {
          errors.name = "Required";
        }

        if (!values.file) {
          errors.file = "Required";
        }

        return errors;
      },
      onSubmit: (values) => {
        setParseFileError("");
        setIsLoading(true);
        Papa.parse(values.file as any, {
          header: true,
          error: (err) => {
            console.error(err);
            setIsLoading(false);
            setParseFileError("Something went wrong, please try again");
          },
          complete: function (results, file: File) {
            if (file.name.split(".").slice(-1)[0] !== "csv" && file.type !== "text/csv") {
              setIsLoading(false);
              return setParseFileError("Only csv files are supported");
            }
            if (results.data instanceof Array) {
              setItems(
                results.data.map((item: any) => ({
                  ...item,
                  id: uuidv4()
                }))
              );
              setParsedCsvFile(results.data);
              openModal();
            } else {
              setParseFileError("Something went wrong, please try again");
            }
            setIsLoading(false);
          }
        });

        Papa.parse(values.file as any, {
          header: false,
          error: (err) => {
            console.error(err);
            setIsLoading(false);
            setParseFileError("Something went wrong, please try again");
          },
          complete: function (results) {
            if (results.data instanceof Array) {
              setParsedCsvFile(results.data);
            } else {
              setParseFileError("Something went wrong, please try again");
            }
            setIsLoading(false);
          }
        });
      }
    });

  //TODO: uncomment when batch with email works in BE
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onClickConrirmModalBtn = (withEmails: boolean) => () => {
    onSendBatch(values.name, parsedCsvFile, selected!, withEmails);
    resetForm();
  };

  const getError = (v: keyof FormValues) => {
    return touched[v] && errors[v] ? errors[v] : undefined;
  };

  const onSelectFile = (f: File) => {
    setFieldValue("file", f, true);
    setTimeout(() => {
      setFieldTouched("file", true);
    }, 0);
  };

  const deleteFile = () => {
    setFieldValue("file", undefined, false);
    setTimeout(() => {
      setFieldTouched("file", false);
      setFieldError("file", undefined);
    }, 0);
  };

  const handleClickDone = () => {
    if (!selected) {
      return setError("Select at least one column");
    }
    // openConfirmModal();
    onSendBatch(values.name, parsedCsvFile, selected!, false);
    resetForm();
  };

  useEffect(() => {
    if (selected || isModalOpened) setError("");
  }, [selected, isModalOpened]);

  return (
    <PageWrapper title="New Batch" onClickBack={onClickBack} data-testid="New Batch">
      <>
        {isLoading && <Loader />}
        <Card className="py-8 px-[50px] grow">
          <form onSubmit={handleSubmit}>
            <Input
              className="pb-4 w-full"
              label="Name*"
              onChange={handleChange}
              onBlur={handleBlur}
              error={getError("name")}
              name="name"
              value={values.name}
              data-testid="new-batch-input"
            />
            <FileUploader onSelect={onSelectFile} error={getError("file")} name="upload-csv" />
            {values.file ? (
              <div className="bg-gray-200 px-4 py-5 flex justify-between items-center my-3 rounded-[5px]">
                <div className="text-lg font-medium text-[15px] leading-[18px] flex items-center">Selected: {values.file.name}</div>
                <button className="IconBtn" onClick={deleteFile}>
                  <Cross />
                </button>
              </div>
            ) : (
              <div className="text-gray text-xs leading-[15px] my-2">
                Make sure that you include the Linkedin URL for each contact that you want to generate an icebreaker for. Your file can contain a
                maximum of 300 contacts.
              </div>
            )}

            <Button type="submit" data-testid="upload-btn">
              Upload
            </Button>
            {parseFileError && <div className="text-[11px] leading-[13px] text-red-500 mt-3">{parseFileError}</div>}
          </form>
        </Card>
        <Modal isOpened={isModalOpened} closeModal={closeModal} empty data-testid="new-batch-modal">
          <div className="bg-white overflow-auto NewBatch__modal rounded-[10px] Card flex flex-col pb-6 max-w-full">
            <div className="pt-5 px-6 font-medium text-lg leading-[22px] pb-0.5">Select the column containing the personal LinkedIn URL</div>
            <div className="overflow-auto Scrollbar pb-6">
              <Table<any[]>
                rows={items || []}
                columns={columns || []}
                className="w-fit"
                select={{
                  selected,
                  onSelect: (v) => {
                    setSelected(v);
                  }
                }}
              />
            </div>
            <div className="text-[11px] leading-[13px] text-red-500 text-end px-5 mb-2 shrink-0 flex justify-end items-center h-3.5">
              {!selected && error}
            </div>
            <div className="px-5 flex justify-end">
              <Button styling="outline" className="mr-[10px]" onClick={closeModal} data-testid="new-batch-modal-cancel-btn">
                Cancel
              </Button>
              <Button onClick={handleClickDone} data-testid="new-batch-modal-done-btn">
                Done
              </Button>
            </div>
          </div>
        </Modal>
        {/* <Modal isOpened={isConfirmModalOpened} closeModal={closeConfirmModal} className="Modal--NewBatch" data-testid="confirm-modal">
          <div className="text-center px-3">
            <div className="text-xl leading-[23px] mb-2 Title font-medium">Want to find emails?</div>
            <div className="text-[13px] leading-[16px] mb-4 max-w-[390px] mx-auto">
              This will use <span className="font-bold">1 additional credit</span> for each contact we can find email addresses for.
            </div>
            <div className="mb-2">
              <Button onClick={onClickConrirmModalBtn(true)} className="w-full" data-testid="confirm-modal-yes-btn">
                Yes
              </Button>
            </div>
            <div>
              <Button styling="outline" onClick={onClickConrirmModalBtn(false)} className="w-full" data-testid="confirm-modal-no-btn">
                No thanks
              </Button>
            </div>
          </div>
        </Modal> */}
      </>
    </PageWrapper>
  );
}
