import { FormikErrors, useFormik } from "formik";
import { useState } from "react";
import { Button } from "../../components/Button/Button";
import Input from "../../components/Input";
import { isDomain } from "../../core/helpers";
import PageWrapper from "../../wrappers/PageWrapper";
import CompanyInfoCompiled from "../../components/Company/CompanyInfoCompiled";
import { ReactComponent as SearchIcon } from "../../icons/search_icon.svg";
import mixpanel from "mixpanel-browser";

interface FormValues {
  link: string;
}

export default function CompanyLookup() {
  const [companyDomain, setCompanyDomain] = useState<string>();
  const [shouldUpdate, setShouldUpdate] = useState<boolean>(false);

  const { handleSubmit, handleChange, handleBlur, errors, touched, values } = useFormik<FormValues>({
    initialValues: {
      link: ""
    },
    validate: (values: FormValues) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.link) {
        errors.link = "Required";
      }
      if (values.link && !isDomain(values.link)) {
        errors.link = "Link is invalid";
      }

      return errors;
    },
    onSubmit: async (values) => {
      setShouldUpdate(true);
      setCompanyDomain(values.link);
      mixpanel.track("search_action", { entity: "company", query: companyDomain });
    }
  });

  return (
    <PageWrapper data-testid="company-lookup">
      <form onSubmit={handleSubmit} className=" flex ">
        <Input
          data-testid="link-input"
          className=" w-full rounded-r-none border-0 h-10 text-sm"
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.link && errors.link ? errors.link : undefined}
          name="link"
          value={values.link}
          placeholder="Enter Company URL"
        />
        <Button type="submit" data-testid="submit-btn" className="rounded-l-none h-10 font-normal text-center flex">
          {<SearchIcon className="mx-2 self-center"></SearchIcon>}
          Search
        </Button>
      </form>
      {companyDomain !== undefined && (
        <CompanyInfoCompiled
          shouldRefetch={shouldUpdate}
          setShouldRefetch={setShouldUpdate}
          companyDomain={companyDomain}
          className="mt-4"></CompanyInfoCompiled>
      )}
    </PageWrapper>
  );
}
