import { useFormik } from "formik";
import FormDropdown from "./FormDropdownInterface";
import FormInput from "./FormInputInterface";
import "./OnboardingForm.scss";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import GenericDropdown from "../Dropdown/GenericDropdown";
import FormDropdownInput from "./FormDropdownInputInterface";
import DropdownInput from "../Dropdown/DropdownInput";

interface Props {
  className: string;
  fields: (FormInput | FormDropdown | FormDropdownInput)[];
  button?: JSX.Element;
  dropdownTrigger?: JSX.Element;
  skipLink?: JSX.Element;
}

export default function GenericForm({ fields, button, dropdownTrigger, skipLink, className }: Props) {
  const formikProps = useFormik({
    initialValues: {},
    onSubmit: async () => {
      return;
    }
  });

  const renderFields = (field: FormInput | FormDropdown | FormDropdownInput) => {
    if (field.type === "input") {
      return (
        <textarea
          id={field.name}
          key={field.name}
          className={`${field.className} resize-none`}
          rows={field.rows || 1}
          onChange={(e) => {
            formikProps.handleChange(e);
            field.onChange(e.target.value);
          }}
          autoComplete="off"
          name={field.name}
          defaultValue={field.value || undefined}
          placeholder={field.placeholder}
          // error={field.regex ? (field.regex.test(field.value) ? "Check your textarea" : undefined) : undefined}
        ></textarea>
      );
    }
    if (field.type === "dropdown") {
      return (
        <div key={field.name}>
          <GenericDropdown
            className={field.className}
            items={field.options}
            name={field.name}
            selected={field.selected}
            selectAllOption={field.selectAllOption}
            isMulti={field.isMulti || false}
            isForm={true}
            placeholder={field.placeholder || ""}
            trigger={dropdownTrigger || <Polygon></Polygon>}></GenericDropdown>
          {field.description && <small className="ml-2 text-gray">{field.description}</small>}
        </div>
      );
    }
    if (field.type === "dropdown_input") {
      return (
        <div key={field.name}>
          <DropdownInput
            className={field.className}
            items={field.options}
            name={field.name}
            selectedInputs={field.selectedInputs}
            selectedDropdownItems={field.selectedDropdownItems}
            placeholder={field.placeholder || ""}
            trigger={dropdownTrigger || <Polygon></Polygon>}
            setExternalState={field.setExternalState}></DropdownInput>
          {field.description && <small className="ml-2 text-gray">{field.description}</small>}
        </div>
      );
    }
  };

  return (
    <form className={`${className} flex-col h-full relative items-center`} onSubmit={formikProps.handleSubmit}>
      {fields.map((f) => renderFields(f))}
      {skipLink && skipLink}
      {button && button}
    </form>
  );
}
