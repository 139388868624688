import { useMemo, useState, useEffect } from "react";
import { Button } from "../../components/Button/Button";
import PageWrapper from "../../wrappers/PageWrapper";
import { ReactComponent as More } from "../../icons/more.svg";
import { ReactComponent as UsersIcon } from "../../icons/users.svg";
import { ReactComponent as Trash } from "../../icons/trash.svg";
import { ReactComponent as ChevronRight } from "../../icons/chevron-right.svg";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import Card from "../../components/Card";
import Table from "../../components/Table";
import Dropdown, { DropdownItem } from "../../components/Dropdown/Dropdown";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import {
  deleteGroup,
  getContacts,
  getGroups,
  postCreateGroup,
  patchGroup,
  deleteContact,
  addExistingContactToGroup,
  setContactOwner,
  getOrganizationMembers,
  getStatusTags,
  getCurrentUser
} from "../../core/api";
import Loader from "../../components/Loader";
import "./Contacts.scss";
import { Contact, Group, Scheduling, Status } from "../../core/types";
import { useNavigate } from "react-router-dom";
import { RoutesEnum } from "../../core/enums";
import { useModal } from "../../hooks/useModal";
import CreateEditNameModal from "../../components/CreateEditNameModal";
import { downloadFile, sortArrayOnCreated } from "../../core/helpers";
import ContactsGroup from "./ContactsGroup";
import { useMedia } from "../../hooks/useMedia";
import { generateCsv } from "./Contacts.helpers";
import classNames from "classnames";
import { FormikErrors } from "formik";
import { useAuth0 } from "@auth0/auth0-react";
import Checkbox from "../../components/Checkbox";
import Pagination from "../../components/Pagination/Pagination";
import usePagination from "../../components/Pagination/usePagination";
import CheckboxDropdown from "../../components/CheckboxDropdown";
import { id } from "date-fns/locale";
import linkedin from "../../icons/social/linkedin_icon.svg";
import Tooltip from "../../components/Tooltip";
import GetEmailComponent from "../../components/ContactDetails/GetEmailComponent";
import OwnedByComponent from "../../components/ContactDetails/OwnedByComponent";
import StatusComponent from "../../components/ContactDetails/StatusComponent";
import { CommentComponent } from "../../components/Comment/CommentComponent";
import ScheduleReminder from "../../components/ContactDetails/ScheduleReminder";

export default function Contacts() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const [groupToEdit, setGroupToEdit] = useState<Group | undefined>();
  const { isModalOpened, openModal, closeModal } = useModal();
  const { getAccessTokenSilently } = useAuth0();
  const [selected, setSelected] = useState<string[]>([]);
  const [exportType, setExportType] = useState<"currentPage" | "all" | undefined>("currentPage");
  const [contacts, groups, orgMembers, statuses, user] = useQueries({
    queries: [
      {
        queryKey: ["Contacts"],
        queryFn: async () =>
          getContacts(await getAccessTokenSilently()).then((res) => {
            setContactsData(res);
            return res;
          }),
        refetchOnMount: true,
        refetchOnWindowFocus: false
      },
      {
        queryKey: ["Groups"],
        queryFn: async () => getGroups(await getAccessTokenSilently())
      },
      {
        queryKey: ["OrgMembers"],
        queryFn: async () => await getOrganizationMembers(await getAccessTokenSilently())
      },
      {
        queryKey: ["Statuses"],
        queryFn: async () => await getStatusTags(await getAccessTokenSilently())
      },
      { queryKey: ["User"], queryFn: async () => getCurrentUser(await getAccessTokenSilently()) }
    ]
  });
  const [contactsData, setContactsData] = useState<Contact[]>(contacts.data || []);
  const [ownerFilter, setOwnerFilter] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<string[]>([]);
  const UNASSIGNED_OWNER_LABEL = "Unassigned";

  const groupsCount = useMedia(
    [
      "(max-width: 1249.9px)",
      "(min-width: 1250px) and (max-width: 1374.9px)",
      "(min-width: 1375px) and (max-width: 1499.9px)",
      "(min-width: 1500px)"
    ],
    [6, 7, 8, 9],
    5
  );

  const allContacts = useMemo(() => contactsData || [], [contactsData]);

  const { currentPage, setCurrentPage, pages, currentPageData } = usePagination(sortArrayOnCreated(allContacts, "ascending"));

  useEffect(() => {
    if (exportType === "currentPage") {
      setExportType(undefined);
      setSelected([]);
    }
  }, [currentPage]);

  const onSubmitCreateEditGroup = (uuid?: string) => async (name: string, resetForm: () => void) => {
    setIsLoading(true);
    const onSuccess = () => {
      closeModal();
      resetForm();
      setGroupToEdit(undefined);
    };

    const action = uuid
      ? patchGroup().then(() => {
          queryClient.setQueryData(["Groups"], (p: Group[] | undefined) => {
            if (!p) return [];

            return p.map((g) => {
              if (g.uuid === uuid) {
                return g;
              }
              return g;
            });
          });
          onSuccess();
        })
      : postCreateGroup(name, await getAccessTokenSilently()).then((res) => {
          queryClient.setQueryData(["Groups"], (p: Group[] | undefined) => {
            if (!p) return [];

            return [...p, res.data];
          });
          onSuccess();
        });

    action.catch(console.error).finally(() => {
      setIsLoading(false);
    });
  };

  const onClickRemoveGroup = (uuid: string) => async () => {
    setIsLoading(true);
    deleteGroup(uuid, await getAccessTokenSilently())
      .then(() => {
        queryClient.setQueryData(["Groups"], (p: Group[] | undefined) => {
          if (!p) return [];
          return p.filter((g) => g.uuid !== uuid);
        });
      })
      .catch(console.error)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const groupsData = groups.data || [];

  const groupNames = groupsData.filter((g) => !groupToEdit || g.uuid !== groupToEdit.uuid).map((g) => g.name);

  const onClickSelectAll = () => {
    setExportType("all");
    setSelected(allContacts.map((c) => c.uuid));
  };

  const onClickSelectCurrentPageContacts = () => {
    setExportType("currentPage");
    setSelected(currentPageData.map((c) => c.uuid));
  };

  const isExportSelectChecked = () => {
    if (exportType === "all") return selected.length === allContacts.length;
    if (exportType === "currentPage") return selected.length === currentPageData.length;
    return false;
  };

  useEffect(() => {
    if (!contacts.data) {
      setContactsData([]);
    }
    //get unassigned contacts if checked;
    const contactDataFiltered: Contact[] = ownerFilter.includes(UNASSIGNED_OWNER_LABEL) ? contacts.data!.filter((c) => !c.owner) : [];
    ownerFilter.length
      ? setContactsData([...contactDataFiltered, ...contacts.data!.filter((c) => c.owner && ownerFilter.includes(c.owner.email))])
      : setContactsData(contacts.data!);
  }, [ownerFilter]);

  useEffect(() => {
    if (!contacts.data) {
      setContactsData([]);
    }
    const unsetStatus = "Not Started";
    //get unassigned contacts if checked;
    const contactDataFiltered: Contact[] = statusFilter.includes(unsetStatus) ? contacts.data!.filter((c) => !c.status) : [];
    statusFilter.length
      ? setContactsData([...contactDataFiltered, ...contacts.data!.filter((c) => c.status && statusFilter.includes(c.status.name))])
      : setContactsData(contacts.data!);
  }, [statusFilter]);

  const ownersDropdownItems = () => {
    if (!orgMembers.data) {
      return [];
    }
    const dropdownItems = orgMembers.data
      .map((orgMember) => orgMember.email)
      .map((email) => {
        return getOwnerDropdownItem(email);
      });
    dropdownItems.push(getOwnerDropdownItem(UNASSIGNED_OWNER_LABEL));
    return dropdownItems;
  };

  function getOwnerDropdownItem(email: string): DropdownItem {
    return {
      label: email,
      checkBox: (
        <Checkbox
          checked={ownerFilter.includes(email)}
          onChange={() => {
            ownerFilter.includes(email) ? setOwnerFilter(ownerFilter.filter((e) => e !== email)) : setOwnerFilter([...ownerFilter, email]);
          }}></Checkbox>
      )
    } as DropdownItem;
  }

  const onOwnershipChange = (ownerUuid: string, contactUuid: string) => {
    const orgMember = orgMembers.data ? orgMembers.data.find((member) => member.uuid === ownerUuid) : null;
    setContactsData((contactsData) =>
      contactsData.map((contact) => {
        if (contact.uuid === contactUuid) {
          contact.owner = orgMember;
        }
        return contact;
      })
    );
  };

  const statusDropdownItems = () => {
    if (!statuses.data) {
      return [];
    }
    const statusData: Status[] = [];
    const firstStatus = statuses.data.find((s) => s.name === "Not Started");
    const secondStatus = statuses.data.find((s) => s.name === "Not Interested");
    if (firstStatus) {
      statusData.push(firstStatus);
    }
    if (secondStatus) {
      statusData.push(secondStatus);
    }
    statuses.data.map((s) => {
      if (!statusData.map((stat) => stat.name).includes(s.name)) {
        statusData.push(s);
      }
    });
    const dropdownItems = statusData.map((status) => {
      return getStatusDropdownItem(status);
    });
    return dropdownItems;
  };

  function getStatusDropdownItem(status: Status): DropdownItem {
    return {
      label: status.name,
      checkBox: (
        <Checkbox
          checked={statusFilter.includes(status.name)}
          onChange={() => {
            statusFilter.includes(status.name)
              ? setStatusFilter(statusFilter.filter((e) => e !== status.name))
              : setStatusFilter([...statusFilter, status.name]);
          }}></Checkbox>
      )
    } as DropdownItem;
  }

  const onStatusChange = (contactUuid: string, status: Status) => {
    setContactsData((contactsData) =>
      contactsData.map((contact) => {
        if (contact.uuid === contactUuid) {
          contact.status = status;
        }
        return contact;
      })
    );
  };

  const onDateSelect = (uuid: string, scheduling: Scheduling | undefined) => {
    setContactsData((contactsData) =>
      contactsData.map((c) => {
        if (c.uuid === uuid) {
          return { ...c, email_notification: scheduling };
        }
        return c;
      })
    );
  };

  if (contacts.isLoading || groups.isLoading) return <Loader />;

  return (
    <PageWrapper
      data-testid="Contacts"
      btn={
        <Button
          data-testid="Export-btn"
          disabled={!selected.length}
          onClick={() => {
            downloadFile(generateCsv(allContacts.filter((c) => selected.includes(c.uuid))), "contacts.csv");
          }}
          className="mb-4">
          Export
        </Button>
      }>
      <Card className="grow pb-4 flex flex-col Contacts">
        {isLoading && <Loader />}
        <div className="grow">
          <div className="min-h-[70px] items-center py-5 pr-[53px] pl-4 flex justify-between border-b border-gray-100 bg-white rounded-tr-[10px] rounded-tl-[10px]">
            <div className="mx-[-8px] flex items-center">
              {groupsData.slice(0, groupsCount).map((g) => (
                <ContactsGroup
                  key={g.uuid}
                  group={g}
                  onClickRename={() => {
                    setGroupToEdit(g);
                    openModal();
                  }}
                  onClickRemove={onClickRemoveGroup(g.uuid)}
                  onClickDownload={() => {
                    const groupContacts = (contacts.data || []).filter((c) => c.groupUuid === g.uuid!);
                    downloadFile(generateCsv(groupContacts), `group-${g.uuid}-contacts.csv`);
                  }}
                />
              ))}
            </div>
            {groupsData.length > groupsCount && (
              <div className="mr-10">
                <Dropdown
                  data-testid="groups-dropdown"
                  className="Dropdown--bottom--right"
                  popupDirection="left"
                  trigger={<More className=" rounded-full w-3" stroke="black" />}
                  items={groupsData.slice(groupsCount).map((g) => ({
                    label: g.name,
                    onClick: () => {
                      navigate(RoutesEnum.Group.replace(":id", `${g.uuid}`));
                    },
                    icon: <UsersIcon />
                  }))}
                />
              </div>
            )}
          </div>
          <Table<Contact>
            className="grow Table--Contacts flex flex-col"
            columns={[
              {
                size: "minmax(160px, 1fr)",
                label: "Name",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Name-${r.uuid}`}>
                    <img
                      src={r.image_url}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = process.env.PUBLIC_URL + "/images/default_profile_image.png";
                      }}
                      className="w-7 h-7 rounded-full"
                    />
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline ml-[6px] Contacts__name">
                      {r.first_name} {r.last_name}
                    </span>
                    <Tooltip content={r.linkedin_url}>
                      <a target={"_blank"} rel="noreferrer" href={r.linkedin_url} onClick={(e) => e.stopPropagation()}>
                        <img className="w-3 h-3 mt-0.5 ml-2 hover:cursor-pointer" src={linkedin}></img>
                      </a>
                    </Tooltip>
                  </div>
                )
              },
              {
                label: "Company",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Company-${r.uuid}`}>
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.company}</span>
                  </div>
                )
              },
              {
                label: "Job Title",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex items-center h-full" data-testid={`Job Title-${r.uuid}`}>
                    <span className="overflow-hidden text-ellipsis whitespace-nowrap block-inline">{r.position}</span>
                  </div>
                )
              },
              {
                label: "Status",
                render: (r) => (
                  <div
                    className={classNames(`text-[11px] leading-[13px] items-center w-full flex h-5 content-center text-center  align-middle  `)}
                    data-testid={`Status-${r.uuid}`}>
                    <StatusComponent
                      style="minimized"
                      contactUuid={r.uuid}
                      currentStatus={r.status || undefined}
                      className=""
                      possibleStatuses={statuses.data || []}
                      onChange={onStatusChange}></StatusComponent>
                  </div>
                ),
                btn: <Dropdown trigger={<Polygon className="ml-1 h-5 w-[14px] pr-1" />} items={statusDropdownItems()}></Dropdown>
              },
              {
                label: "Owner",
                render: (r) => (
                  <div
                    className={classNames(`text-[11px] leading-[13px] rounded-md  items-center  flex h-5 w-full align-middle `)}
                    data-testid={`Owner-${r.uuid}`}>
                    <OwnedByComponent
                      className=""
                      style="minimized"
                      targetUuid={r.uuid}
                      ownerEmail={r.owner?.email || undefined}
                      orgMembers={orgMembers.data || []}
                      onChange={onOwnershipChange}
                      setNewOwnerApiCall={setContactOwner}></OwnedByComponent>
                  </div>
                ),
                btn: <Dropdown trigger={<Polygon className="ml-1 h-5 w-[14px] pr-1" />} items={ownersDropdownItems()}></Dropdown>
              },
              {
                label: "Email",
                render: (r) => (
                  <div
                    className="text-[11px] leading-[13px] flex  items-center h-full truncate"
                    data-testid={`Email-${r.uuid}`}
                    onClick={(e) => e.stopPropagation()}>
                    <GetEmailComponent
                      className={" truncate align-middle"}
                      email={r.email}
                      isSaved={true}
                      emailStatus={r.email_status || null}
                      contactUuid={r.uuid}
                      styling="table"></GetEmailComponent>
                  </div>
                )
              },
              {
                label: "Notes",
                render: (r) => (
                  <div className="text-[11px] leading-[13px] flex items-center h-full contents-center " data-testid={`Added Date-${r.uuid}`}>
                    {user.data && (
                      <CommentComponent
                        targetUuid={r.uuid}
                        user={user.data}
                        style="minimized"
                        comments={r.comments || []}
                        type="contact"></CommentComponent>
                    )}
                  </div>
                )
              },
              {
                label: "Scheduling",
                empty: true,
                size: "36px",
                render: (r) => <ScheduleReminder targetUuid={r.uuid} scheduling={r.email_notification} onUpdate={onDateSelect} className={""} />
              },
              {
                label: "Actions",
                empty: true,
                size: "36px",
                render: (r) => (
                  <div
                    className="text-[11px] leading-[13px] flex items-center justify-end h-full cursor-default"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Dropdown
                      data-testid={`contact-more-btn-${r.uuid}`}
                      className="Dropdown--Contacts"
                      popupDirection="left"
                      trigger={<More stroke="black" />}
                      items={[
                        {
                          label: "Delete",
                          onClick: async () => {
                            setIsLoading(true);
                            deleteContact(r.uuid, await getAccessTokenSilently())
                              .then(() => {
                                queryClient.setQueryData<Contact[] | undefined>(["Contacts", id], () => {
                                  if (!contactsData) {
                                    return;
                                  }
                                  const updatedData = contacts.data?.filter((c) => c.uuid !== r.uuid);
                                  setContactsData(updatedData || []);
                                  return updatedData;
                                });
                              })
                              .catch((e) => console.warn(`caught error while trying to delete contact: ${e}`))
                              .finally(() => setIsLoading(false));
                          },
                          icon: <Trash />
                        }
                      ]}>
                      <Dropdown
                        data-testid={`dropdown-add-to-group-${r.uuid}`}
                        className="Dropdown--Submenu"
                        popupDirection="left"
                        trigger={(isOpened) => {
                          return (
                            <div
                              className={classNames(
                                "flex items-center Dropdown__item cursor-pointer text-[11px] leading-[13px] text-gray hover:text-purple",
                                {
                                  "Dropdown__item--active": isOpened
                                }
                              )}>
                              <div className="Dropdown__icon flex items-center">
                                <UsersIcon />
                              </div>
                              Add to group
                              <div className="Dropdown__icon Dropdown__iconRight flex items-center">
                                <ChevronRight />
                              </div>
                            </div>
                          );
                        }}
                        items={(groups.data || []).map((g) => ({
                          label: g.name,
                          onClick: async () => {
                            setIsLoading(true);
                            addExistingContactToGroup(r.uuid, g.uuid, await getAccessTokenSilently()).then(() => {
                              const temp = contactsData?.map((c) => {
                                if (c.uuid === r.uuid) {
                                  return { ...c, groupUuid: g.uuid };
                                }
                                return c;
                              });
                              setContactsData(temp);
                              setIsLoading(false);
                              queryClient.setQueryData<Contact[] | undefined>(["Contacts"], (contacts) => {
                                return (contacts || []).map((c) => {
                                  if (c.uuid === r.uuid) {
                                    return {
                                      ...c,
                                      groupUuid: g.uuid
                                    };
                                  }
                                  return c;
                                });
                              });
                            });
                          },
                          icon: <UsersIcon />
                        }))}
                      />
                    </Dropdown>
                  </div>
                )
              },
              {
                label: "Select",
                empty: true,
                size: "33px",
                render: (c) => (
                  <div
                    className="flex items-center justify-center h-full cursor-default"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}>
                    <Checkbox
                      onChange={(e) => {
                        setSelected((s) => (e.target.checked ? [...s, c.uuid] : s.filter((uuid) => uuid !== c.uuid)));
                      }}
                      checked={selected.includes(c.uuid)}
                      data-testid={`contact-checkbox-${c.uuid}`}
                    />
                  </div>
                ),
                btn: (
                  <div className="absolute right-5 ">
                    <CheckboxDropdown
                      onChange={(e) => {
                        if (e.target.checked) {
                          onClickSelectCurrentPageContacts();
                        } else {
                          setExportType(undefined);
                          setSelected([]);
                        }
                      }}
                      checked={isExportSelectChecked()}
                      items={[
                        {
                          className: classNames({
                            "Dropdown__item--active": exportType === "currentPage"
                          }),
                          label: `Select this page (${currentPageData.length})`,
                          onClick: onClickSelectCurrentPageContacts,
                          testId: "export-page"
                        },
                        {
                          className: classNames({
                            "Dropdown__item--active": exportType === "all"
                          }),
                          label: `Select all people (${allContacts.length})`,
                          onClick: onClickSelectAll,
                          testId: "export-all"
                        }
                      ]}
                    />
                  </div>
                )
              }
            ]}
            rows={currentPageData}
          />
          <Pagination pages={pages} currentPage={currentPage} setCurrentPage={setCurrentPage} />
        </div>
        <div className="pl-[18px] pr-[29px]">
          <Button
            styling="outline-purple"
            className="w-full"
            onClick={() => {
              setGroupToEdit(undefined);
              openModal();
            }}
            size="lg">
            Create new contact group
          </Button>
        </div>
        <CreateEditNameModal
          data-testid="create-edit-group-modal"
          placeholder="Group name"
          initialName={groupToEdit?.name}
          isModalOpened={isModalOpened}
          closeModal={closeModal}
          onSubmit={onSubmitCreateEditGroup(groupToEdit?.uuid)}
          btnLabel={groupToEdit ? "Rename group" : "Create new group"}
          validate={(values) => {
            const errors: FormikErrors<typeof values> = {};

            if (!values.name) {
              errors.name = "Required";
            }

            if (values.name && groupNames.includes(values.name)) {
              errors.name = `Group name is already used`;
            }

            return errors;
          }}
        />
      </Card>
    </PageWrapper>
  );
}
