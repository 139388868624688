import facebook from "../icons/social/facebook_icon.svg";
import twitter from "../icons/social/twitter_icon.svg";
import linkedin from "../icons/social/linkedin_icon.svg";
import Tooltip from "./Tooltip";

interface Links {
  linkedin_url?: string;
  facebook_url?: string;
  twitter_url?: string;
}

interface Props {
  data: Links;
  className?: string;
}

export default function SocialMediaButtons({ className, data: { linkedin_url, facebook_url, twitter_url } }: Props) {
  return (
    <div className={`${className}`}>
      <ol className="flex">
        {linkedin_url && (
          <Tooltip content={linkedin_url}>
            <a target={"_blank"} rel="noreferrer" href={linkedin_url}>
              <img className="w-5 h-5 mt-1 mr-2 hover:cursor-pointer" src={linkedin}></img>
            </a>
          </Tooltip>
        )}
        {facebook_url && (
          <Tooltip content={facebook_url}>
            <a target={"_blank"} rel="noreferrer" href={facebook_url}>
              <img className="w-7 h-7 mb-0.5 mr-2 hover:cursor-pointer" src={facebook}></img>
            </a>
          </Tooltip>
        )}
        {twitter_url && (
          <Tooltip content={twitter_url}>
            <a target={"_blank"} rel="noreferrer" href={twitter_url}>
              <img className="w-7 h-7 hover:cursor-pointer" src={twitter}></img>
            </a>
          </Tooltip>
        )}
      </ol>
    </div>
  );
}
