import React, { useEffect, useState } from "react";
import { DropdownItem } from "./Dropdown";
import { ReactComponent as Polygon } from "../../icons/polygon.svg";
import { useDropdown } from "./useDropdown";
import Checkbox from "../Checkbox";

interface DropdownProps {
  items: DropdownItem[];
  name: string;
  placeholder: string;
  trigger?: JSX.Element;
  className?: string;
  isMulti?: boolean;
  selectAllOption?: boolean;
  selected?: DropdownItem[];
  isForm?: boolean;
}

export const getDropDownItems = (items: string[], event: (label: string) => void) => {
  return items.map((item) => {
    return {
      label: item,
      value: item,
      checkboxOnChange: () => event(item)
    } as DropdownItem;
  });
};

const GenericDropdown: React.FC<DropdownProps> = ({
  items,
  name,
  placeholder,
  trigger,
  selected,
  selectAllOption,
  isMulti = false,
  isForm = false,
  className
}) => {
  const [selectedItems, setSelectedItems] = useState<DropdownItem[]>(selected || []);
  const { dropdownRef, menuRef, onClickTrigger, isOpened } = useDropdown();

  useEffect(() => {
    setSelectedItems(selected || []);
  }, [selected]);

  const handleItemClickEvent = (item: DropdownItem, e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    if (item.onClick) {
      item.onClick(e);
    }
    if (isMulti) {
      selectedItems.map((selected) => selected.label).includes(item.label)
        ? setSelectedItems((selectedItems) => selectedItems.filter((i) => i.label !== item.label))
        : setSelectedItems((selectedItems) => [...selectedItems, item]);
    } else {
      selectedItems.length && selectedItems[0].label === item.label ? setSelectedItems([]) : setSelectedItems([item]);
      onClickTrigger(e);
    }
  };

  const handleCheckboxOnChange = (item: DropdownItem, e: React.ChangeEvent<HTMLInputElement>) => {
    item.checkboxOnChange!(e);
    selectedItems.map((selected) => selected.label).includes(item.label)
      ? setSelectedItems((selectedItems) => selectedItems.filter((i) => i.label !== item.label))
      : setSelectedItems((selectedItems) => [...selectedItems, item]);
  };

  const selectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    if (selectedItems.length === items.length) {
      for (const item of items) {
        handleCheckboxOnChange(item, e);
      }
    } else {
      const selectedLabels = selectedItems.map((i) => i.label);
      for (const item of items) {
        if (!selectedLabels.includes(item.label)) {
          handleCheckboxOnChange(item, e);
        }
      }
    }
  };

  return (
    <div ref={dropdownRef} key={`${name}-dropdown`} className={`dropdown relative ${className}`} onClick={onClickTrigger}>
      <div className="flex-col w-full">
        <div className="flex items-center">
          <div
            className={`${isForm ? "w-full pl-1 ml-2" : "border-[0.2px] w-fit pl-2"} ${
              isForm ? " text-gray" : selectedItems.length ? "border-[#877CFF] text-[#877CFF]" : "border-gray-100 text-gray"
            }  rounded-lg flex items-center  min-h-[30px]  dropdown-toggle pr-5  overflow-clip`}>
            {selectedItems && selectedItems.length && !isMulti ? (
              <div className="pr-1">{selectedItems[0].label}</div>
            ) : (
              <div className="placeholder pr-1">{placeholder}</div>
            )}

            <div id="trigger_div" className="absolute right-2 flex items-center justify-center">
              <div className=" flex items-center justify-center">{trigger ? trigger : <Polygon></Polygon>}</div>
            </div>
          </div>
        </div>
        {isOpened && (
          <div ref={menuRef} className={`dropdown-menu ${isForm ? "w-full" : "w-fit"}   `}>
            {selectAllOption && (
              <div
                key={"selectAll-option"}
                className={`dropdown-item flex items-center pl-2 ${items[0].className}`} //a bit sketchy but needed for modification capabilities, consider moving to props here instead.
                onClick={(e) => {
                  e.stopPropagation();
                }}>
                <Checkbox checked={selectedItems.length === items.length} onChange={(e) => selectAll(e)} />
                <p className="ml-2">{"Select All"}</p>
              </div>
            )}
            {items.map((item, index) =>
              item.checkboxOnChange ? (
                <div
                  key={item.label + index}
                  className={`dropdown-item flex items-center px-2 whitespace-nowrap ${item.className}`}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}>
                  <Checkbox checked={selectedItems.map((i) => i.label).includes(item.label)} onChange={(e) => handleCheckboxOnChange(item, e)} />
                  <p className="ml-2">{item.label}</p>
                </div>
              ) : (
                <div
                  key={item.label + index}
                  className={`dropdown-item  px-2 ${selectedItems.map((i) => i.label).includes(item.label) ? "bg-[var(--primary)] text-white" : ""} ${
                    item.className
                  }`}
                  onClick={(e) => handleItemClickEvent(item, e)}>
                  {item.label}
                </div>
              )
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenericDropdown;
